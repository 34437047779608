const menuFixed = () =>{
    let navbar = document.querySelector(".header");
    let body = document.querySelector(`body`);
    let navPos = navbar.getBoundingClientRect().top;

    window.addEventListener("scroll", e => {
    let scrollPos = window.scrollY;
    if (scrollPos > navPos) {
        navbar.classList.add('fixed');
    } else {
        navbar.classList.remove('fixed');
    }
    });
}

export default menuFixed;