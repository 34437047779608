const calcPizza = () => {
    const blocks = document.querySelectorAll(`.pizza .block`);
        
    
    blocks.forEach(block =>{
            let price = block.querySelector(`.block__price`),
            size = block.querySelector(`.block__price_size`),
            input = block.querySelectorAll(`.block__numbers_sizes .block__size`),
            half = block.querySelector(`.block__half .block__size`),
            btns = block.querySelectorAll(`.block__btn`);
            input.forEach((item,i) =>{
                let dataPrice = item.getAttribute("data-price");
                
                item.addEventListener(`change` , (e) =>{
                    let target = e.target;
                    btns.forEach(btn =>{
                        btn.style.display = `none`;
                    });
                    btns[i].style.display = 'block';
                    price.textContent = `${dataPrice}`;
                    size.textContent = `${item.value}см`;
                });
            });
            input[1].click();
    })
    
    
}

export default calcPizza;