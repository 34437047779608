import menuFixed from "./modules/menuFixed";
import menuActive from "./modules/menuActive";
import sliders from "./modules/slider";
import calcPizza from "./modules/calcPizza";
import linkScroll from "./modules/linkScroll";

document.addEventListener(`DOMContentLoaded`, () => {
    menuActive();
    // menuFixed();
    sliders();
    calcPizza();
    linkScroll(`.main__link`);

    
});