const sliders = () =>{
    const mainSlider = new Swiper('.main__swiper', {
        speed: 400,
        slidesPerView: 'auto',
        spaceBetween: 30,
        autoHeight: true,
        allowTouchMove: true,
        centeredSlides: true,
        loop: true,
        navigation: {
                nextEl: '.main-swiper-button-next',
                prevEl: '.main-swiper-button-prev',
        },
      });
}

export default sliders;