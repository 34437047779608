const menuActive = () =>{
    
      let navbar = document.querySelector(".header");
      let viewportHeight = window.innerHeight;
      let navHeight = document.querySelector(".header").offsetHeight;
      let navWidth = document.querySelector(`.main__list_header`);
      let navbarLinks = document.querySelectorAll(".main__link");
      let linkActive = document.querySelectorAll(`.main__link_active`);
      
      window.addEventListener("scroll", (e) => {
        let scrollpos = window.scrollY;
        navbarLinks.forEach((link,i) => {
          let section = document.querySelector(link.hash);
          if (section.offsetTop <= scrollpos + 150 &&
            section.offsetTop + section.offsetHeight > scrollpos + 150) {
            link.classList.add("main__link_active");
            // const container = document.querySelector('.main__list_header');
            // let containerRect = container.getBoundingClientRect();
            // let activeOptionRect = link.getBoundingClientRect();
            // if (containerRect.left + containerRect.width < activeOptionRect.left + activeOptionRect.width){
            // container.scrollLeft += (activeOptionRect.left / 2) - containerRect.left;
            // }
            // else if (activeOptionRect.left < containerRect.left){
            // container.scrollLeft -= containerRect.left - (activeOptionRect.left / 2);
            // }
            } else {
            link.classList.remove("main__link_active");
          }
        });
      });
      
      




}

export default menuActive;